<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("DPA_PPA.ACCOUNTS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openDpaAccountCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_DPA_ACCOUNTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("DPA_PPA.ADD_DPA_ACCOUNT")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_DPA_ACCOUNTS)"
              :objectType="'dpa-accounts'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <dpa-account-list-table
        @onViewDpaAccount="openDpaAccountViewModal"
        @onEditDpaAccount="openDpaAccountEditModal"
        @onDeleteDpaAccount="deleteDpaAccount"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewDpaAccountModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewDpaAccountModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'DPA_ACCOUNT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.VIEW_DPA_ACCOUNT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openDpaAccount"
                :objectType="'dpa-accounts'"
                :objectId="openDpaAccount.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openDpaAccountEditModal(openDpaAccount)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteDpaAccount(openDpaAccount)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeDpaAccountModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-dpa-account-component
              v-if="openDpaAccount"
              :dpaAccountId="openDpaAccount.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditDpaAccountModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditDpaAccountModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'DPA_ACCOUNT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.EDIT_DPA_ACCOUNT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openDpaAccountViewModal(openDpaAccount)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeDpaAccountModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-dpa-account-component
              v-if="openDpaAccount"
              :dpaAccountId="openDpaAccount.id"
              @onViewDpaAccount="openDpaAccountViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddDpaAccountModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddDpaAccountModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'DPA_ACCOUNT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("DPA_PPA.ADD_DPA_ACCOUNT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeDpaAccountModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-dpa-account-component @onViewDpaAccount="openDpaAccountViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DpaAccountListTable from "./partials/DpaAccountListTable.vue";
import EditDpaAccountComponent from "./components/EditDpaAccountComponent.vue";
import AddDpaAccountComponent from "./components/AddDpaAccountComponent.vue";
import ViewDpaAccountComponent from "./components/ViewDpaAccountComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    DpaAccountListTable,
    NotificationSubscription,
    EditDpaAccountComponent,
    AddDpaAccountComponent,
    ViewDpaAccountComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const dpaAccountId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewDpaAccountModalOpened = false;
    let isEditDpaAccountModalOpened = false;
    let isAddDpaAccountModalOpened = false;
    let openDpaAccount = null;
    if (dpaAccountId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewDpaAccountModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditDpaAccountModalOpened = true;
      }
      openDpaAccount = { id: dpaAccountId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddDpaAccountModalOpened = true;
    }
    return {
      isViewDpaAccountModalOpened: isViewDpaAccountModalOpened,
      isEditDpaAccountModalOpened: isEditDpaAccountModalOpened,
      isAddDpaAccountModalOpened: isAddDpaAccountModalOpened,
      openDpaAccount: openDpaAccount,
      renderKey: 1,
    };
  },

  methods: {
    openDpaAccountCreateModal() {
      this.closeDpaAccountModal();
      this.isAddDpaAccountModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaAccounts",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openDpaAccountViewModal(dpaAccount, reRender = false) {
      this.closeDpaAccountModal();
      this.openDpaAccount = dpaAccount;
      this.isViewDpaAccountModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaAccounts",
          query: { id: this.openDpaAccount.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openDpaAccountEditModal(dpaAccount) {
      this.closeDpaAccountModal();
      this.openDpaAccount = dpaAccount;
      this.isEditDpaAccountModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaAccounts",
          query: { id: this.openDpaAccount.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeDpaAccountModal() {
      this.isAddDpaAccountModalOpened = false;
      this.isViewDpaAccountModalOpened = false;
      this.isEditDpaAccountModalOpened = false;
      this.openDpaAccount = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListDpaAccounts",
          query: {},
        }).href
      );
    },

    async deleteDpaAccount(dpaAccount) {
      const confirmation = await swal.fire({
        title: this.$t("DPA_PPA.DELETE_THIS_DPA_ACCOUNT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("dpaAccounts/destroy", dpaAccount.id);
          this.renderKey++;
          this.closeDpaAccountModal();
          this.$notify({
            type: "success",
            message: this.$t("DPA_PPA.ACCOUNT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
