<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="checkAddressInformation"
      @keydown.enter.prevent="() => {}"
    >
      <!-- Organization -->
      <div class="w-100">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !hideOrganization
          "
        >
          <organization-selector
            :organization="dpaAccount.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                dpaAccount.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!dpaAccount.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div class="form-group-wrapper">

        <div class="credit-account-form row mt-3">
          <h3 class="col-12 my-2">{{ $t("DPA_PPA.DPA") }}</h3>
          <!-- credit_data_center_code -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.ISSUER_NUMBER')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.credit_issuer_number"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.credit_issuer_number" />
          </div>

          <!-- credit_data_center_code -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.DATA_CENTER_CODE')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.credit_data_center_code"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.credit_data_center_code" />
          </div>

          <!-- credit_bank_format -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.BANK_FORMAT')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.credit_bank_format"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.credit_bank_format" />
          </div>
          
          <!-- credit_file_name -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.FILE_NAME')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.credit_file_name"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.credit_file_name" />
          </div>
        </div>

        <div class="debit-account-form mt-3  mb-3 row">
          <h3 class="col-12 my-2">{{ $t("DPA_PPA.PPA") }}</h3>
          <!-- debit_data_center_code -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.ISSUER_NUMBER')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.debit_issuer_number"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.debit_issuer_number" />
          </div>

          <!-- debit_data_center_code -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.DATA_CENTER_CODE')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.debit_data_center_code"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.debit_data_center_code" />
          </div>

          <!-- debit_bank_format -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.BANK_FORMAT')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.debit_bank_format"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.debit_bank_format" />
          </div>
          
          <!-- debit_file_name -->
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('DPA_PPA.FILE_NAME')}`"
              inputClasses="text-capitalize"
              v-model="dpaAccount.debit_file_name"
              @change="() => {onFormChanged();}"
            />
            <validation-error :errors="apiValidationErrors.debit_file_name" />
          </div>
        </div>

        <!-- transaction_callback_url -->
        <div class="w-100">
          <base-input
            :label="`${$t('DPA_PPA.TRANSACTION_CALLBACK_URL')}`"
            inputClasses="text-capitalize"
            v-model="dpaAccount.transaction_callback_url"
            @change="() => {onFormChanged();}"
          />
          <validation-error :errors="apiValidationErrors.transaction_callback_url" />
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              dpaAccount.id
                ? $t("DPA_PPA.EDIT_ACCOUNT")
                : $t("DPA_PPA.ADD_ACCOUNT")
            }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { defaultAddressInformation } from "@/constants/defaultAddressInformation";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: [
    "dpaAccountData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    let dpaAccountData = { ...this.dpaAccountData };
    dpaAccountData = this.$fillUserOrganizationData(dpaAccountData);
    return {
      dpaAccount: dpaAccountData,
      phoneTypesOptions,
      dpaAccounts: [],
      total: 0,
      showModal: false,
      defaultAddressInformation,
      processing: false,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const checkUniqueDpaAccount = await this.checkDpaAccountUniqueInformation();
      if (checkUniqueDpaAccount) {
        await this.sendInformation();
      } else {
        return;
      }
    },

    async sendInformation() {
      let dpaAccountData = cloneDeep(this.dpaAccount);
      dpaAccountData = this.$fillUserOrganizationData(dpaAccountData);
      this.$emit("dpaAccountSubmitted", dpaAccountData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.dpaAccount?.organization_id}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "organization",
        };

        if (this.dpaAccount?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.dpaAccount?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("dpaAccounts/list", params);
        this.dpaAccounts = this.$store.getters["dpaAccounts/list"];
        this.total = this.$store.getters["dpaAccounts/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkDpaAccountUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.dpaAccount?.id;
        default:
          return false;
      }
    },

    checkAddressInformation() {
      this.handleSubmit();
    },

  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    dpaAccountData(dpaAccountData) {
      if (dpaAccountData) {
        this.dpaAccount = {
          ...this.dpaAccount,
          ...cloneDeep(dpaAccountData),
        };
      }
    },
  },
};
</script>
