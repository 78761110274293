<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!dpaAccount">
      <span class="loader"></span>
    </span>
    <dpa-account-form
      v-if="dpaAccount"
      :loading="loading"
      :dpaAccountData="dpaAccount"
      :formErrors="formErrors"
      @dpaAccountSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import DpaAccountForm from "../partials/DpaAccountForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { DpaAccountForm },

  mixins: [alertLeave],

  props: {
    dpaAccountId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      dpaAccount: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("dpaAccounts/get", this.dpaAccountId);
        this.dpaAccount = this.$store.getters["dpaAccounts/dpaAccount"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(dpaAccount) {
      this.loading = true;
      const dpaAccountData = cloneDeep(dpaAccount);

      try {
        await this.$store.dispatch("dpaAccounts/update", dpaAccountData);
        this.$notify({
          type: "success",
          message: this.$t("DPA_PPA.ACCOUNT_UPDATED"),
        });
        const dpaAccount = await this.$store.getters["dpaAccounts/dpaAccount"];
        this.$emit("onViewDpaAccount", dpaAccount, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
